export const TestIds = {
  visibleItemsContainer: 'slider-gallery-visible-items-container',
  allItemsContainer: 'slider-gallery-all-items-container',
  swipeLeftHitArea: 'slider-gallery-swipe-left',
  swipeRightHitArea: 'slider-gallery-swipe-right',
};

export const translations = {
  ARIA_LABEL_NAMESPACE: 'ariaLabels',
  SLIDER_GALLERY_ARIA_LABEL_KEY: 'SliderGallery_AriaLabel_TopLevel',
  SLIDER_GALLERY_ARIA_LABEL_DEFAULT: 'Slider gallery',
  SLIDER_PLAY_BACKWARD_ARIA_LABEL_KEY: 'SliderGallery_AriaLabel_Left_Button',
  SLIDER_PLAY_BACKWARD_ARIA_LABEL_DEFAULT: 'play backward',
  SLIDER_PLAY_FORWARD_ARIA_LABEL_KEY: 'SliderGallery_AriaLabel_Right_Button',
  SLIDER_PLAY_FORWARD_ARIA_LABEL_DEFAULT: 'play forward',
  SLIDER_PLAY_BUTTON_DESCRIPTION_KEY: 'playButtonsDescription',
  SLIDER_PLAY_BUTTON_DESCRIPTION_DEFAULT:
    'To play, press and hold the enter key. To stop, release the enter key.',
};

export const navButtonsDescriptionElementId = 'play-button-description';
