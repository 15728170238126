import SliderGallery_SliderGalleryNoArrowComponent from '@wix/thunderbolt-elements/src/components/NativeGalleries/SliderGallery/viewer/skinComps/SliderGalleryNoArrow/SliderGalleryNoArrow.skin';


const SliderGallery_SliderGalleryNoArrow = {
  component: SliderGallery_SliderGalleryNoArrowComponent
};


export const components = {
  ['SliderGallery_SliderGalleryNoArrow']: SliderGallery_SliderGalleryNoArrow
};

